import React from "react"
import { Link } from "gatsby"
import styled from '~modules/styled-components'
import data from '~data/links.yml'

const Nav = styled.nav`
@media(max-width: 767px){
  background-color: #000;
  }
  background-color: transparent;
  gap: 20px;
  .menuItem a {
    color: #fff;  
    text-decoration: none;
    font-size: 1.8rem;
    &.active {
      color: #F04191;
    }
    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }
    @media (min-width: 768px) {
  display: flex;
  font-size: 20px;
  }
`

const Links = ({open}) => {
  const Items = data.items.map((item, i) => {
    return (
      <div className="menuItem" key={"link-"+i}>
        <Link to={item.link} >
          {item.label}
        </Link>
      </div>
    )
  })
  return <Nav className="top_nav hidden_small" open={open} >{Items}</Nav>
}

export default Links
