import React, { useState } from 'react';
import DateTimePicker from '~modules/react-datetime-picker';
import styled from "~modules/styled-components"
import widget from "~data/widget.yml"

const Widget = (p) => {
  const defaultDate = () => {
    var d = new Date()
    d.setHours(12)
    d.setMinutes(0)
    return d
  }

  const [value, onChange] = useState(defaultDate());
  const [valueExit, onChangeExit] = useState(defaultDate());
  return (
    <ParkingWidget id="reserve">
      <h2>{widget.park_title}</h2>
      <Form method="get" action={widget.form.action} rel="noopener noreferrer nofollow" target="_blank">
        <InBox>
          <label>Parking Check In<br />
           <DateTimePicker name="arrival" onChange={onChange} value={value} required="" id="arrival" disableClock={true}/>
          </label>
        </InBox>
        <InBox>
          <label>Parking Check Out<br />
          <DateTimePicker name="exit" onChange={onChangeExit} value={valueExit} required="" id="exit" disableClock={true}/>
          </label>
        </InBox>
        <div className="search bottom">
          <input type="hidden" name={widget.form.hidden.name} value={widget.form.hidden.value} />
          <Submit type="submit" className="submit button">Search</Submit>
        </div>
      </Form>
    </ParkingWidget>
  )
}

export default Widget

const ParkingWidget = styled.div`
  width: 90%;
  position: relative;
  text-align: center;
  margin: 0 auto;
  z-index: 2;
  padding: 35px 20px 60px;
  max-width: 1040px;
  background: #272727!important;
  display: grid;
  align-content: center;
  color: #fff;
  border-radius: 11px;
  h2 {
    font-size: 45px;
    font-weight: 600;
    line-height: 1;
    text-shadow: -4px 3px 5px black;
    margin-bottom: 25px;
    }

  @media(min-width: 768px) {
    padding: 35px 50px 60px;
  }

`
const Form = styled.form`
  display: grid;
  gap: 10px;
  align-items: end;
  @media(min-width: 768px){
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

`
const InBox = styled.div`
  display: grid;
  text-align: left;
  * {
    color: #fff;
  }

  .react-datetime-picker {
    width: 100%;
    background-color: #fff;
    outline: none!important;
    font-size: 12px;
    border: none!important;
    height: 44px;
    line-height: 18px;
    padding: 10px;
    border-radius: 5px;  
    .react-datetime-picker__inputGroup__divider:nth-child(9),
    .react-datetime-picker__inputGroup__leadingZero,
    .react-datetime-picker__inputGroup__hour,
    .react-datetime-picker__inputGroup__minute,
    .react-datetime-picker__inputGroup__amPm {
      color: #fff;
      display: none;
    }

    * {
      color: #777;
      border: none;
      outline: none;
    }  
  }

`
const Submit = styled.button`
    background: #ec0707;
    color: white;
    border-color: #ec0707;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: none;
    outline: none;
`
