import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "~parts/layout"
import Seo from "~parts/seo"
import Hero from "~parts/hero"
import TextMDX from '~parts/textMDX'
import SectionWidgets from "~parts/section_widgets"

const IndexPage = ({ data }) => {
  const Sections = data.mdx.edges.map((n, i) => {
    return (
      <>
        <TextMDX mdx={n.node} key={"nodeMdx-" + i} />
        {n.node.frontmatter.order === 1 ? <SectionWidgets /> : ""}
      </>
    )
  })
  return (
    <Layout>
        <Seo />
        <Hero />
      {Sections}
    </Layout>
  )
}

export const query = graphql`
query MdxQuery {
  mdx: allMdx(
    filter: {frontmatter: {type: {eq: "text-section"}}}
    sort: {fields: frontmatter___order}
  ) {
    edges {
      node {
        id
        body
        frontmatter {
          className
          id
          location
          order
          title
          type
        }
      }
    }
  }
}

`
export default IndexPage
