import React from "react"
import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"
import styled from '~modules/styled-components'

const LogoOuter = styled.div`
  z-index: 5;
  align-self: center;
  .Logo {
    width: 40px;
    height: auto;
    margin-bottom: 0;
    outline: 2px solid #3861b0;
    border: 2px solid #fff;
    border-radius: 5px;
    @media (min-width: 768px){
      width: 60px;
      height: auto;
      }
    }
`

const Logo = () => {
  return (
    <LogoOuter>
      <Link to="/">
        <img src="/images/logo.png" alt="logo" className="Logo" />
      </Link>
    </LogoOuter>
  )
}
export default Logo
