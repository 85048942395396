import React from 'react'
import styled from '~modules/styled-components'
import data from '~data/faq.yml'

const Container = styled.div`
  max-width: 1080px;
  padding: 20px;
  margin: 0 auto;
`

const FaqSection = () => {
  var Txt = () => {
    return (
      data.items.map((item, i) => {
        return (
          <div key={"txt-"+i}>
            <h3>{item.q}</h3>
            <p>{item.a}</p>
          </div>
        )
      })
    )
  }

  var sectionId = (data.id ? data.id : data.title)
  return (
    <section id={sectionId} >
      <Container>
        <h2>{data.title}</h2>
        <Txt />
      </Container>
    </section>
  )
}

export default FaqSection
