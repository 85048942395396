import React, { useState } from "react"
import styled from "~modules/styled-components"
import NavbarLinks from "./links"
import Logo from "./logo"

const Navbar = (path) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation>
    <Container>
      <Logo />
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)} >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <NavBox>
          <NavbarLinks />
        </NavBox>
      ) : (
        <NavBox open>
          <NavbarLinks />
        </NavBox>
      )}
    </Container>
  </Navigation>
  )
}

const Navigation = styled.nav`
z-index: 100;
  height: 100px;
  display: flex;
  background-image: linear-gradient(180deg, black, transparent);
  position: absolute;
  width: 100%;
  @media (max-width: 767px) {
    height: fit-content;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }

`

const Toggle = styled.div`
  display: none;
  align-self: center;
  cursor: pointer;
  padding: 0 30px;
  position: absolute;
  right: 0;
  @media (max-width: 767px) {
    display: flex;
  }

`

const NavBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 7;
  align-self: center;

  @media (max-width: 767px) {
    flex-direction: column;
    height: fit-content;
    position: absolute;
    width: 100%;
    justify-content: flex-start;
    padding: 30px 30px 40px;
    background-color: #000;
    transition: all 0.3s ease-in;
    top: 100px;
    left: ${props => (props.open ? "-100%" : "0")};
  }

`

const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }

`
const Container = styled.div`
  max-width: 1100px;
  justify-content: space-between;
  display: flex;
  gap: 30px;
  height: 100px;
  z-index: 8;
  margin: 0 auto 30px;
  width: 100%;
  padding: 24px 32px;
`

export default Navbar
