import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import styled from '~modules/styled-components'
import { GatsbyImage } from "~modules/gatsby-plugin-image"
import GetImageByName from "~components/getImageByName"
import config from "~data/config.yml"

const PartnersSection = styled.section`
  margin-bottom: 30px;
`
const Container = styled.div`
  max-width: 1080px;
  padding: 20px;
  margin: 0 auto;
  .access_text {
    font-size: 22px;
    font-weight: 500;
    margin-top: 50px;
  }
`
const PartnerItem = styled.div`
  margin-bottom: 40px;
  .pItem {
    margin-bottom: 20px;
  }
`
const Partners = () => {
  const data = useStaticQuery(
    graphql`
    query AllPartnersQuery {
    md:   allMarkdownRemark(filter: {frontmatter: {type: {eq: "partners-section"}}} sort: {fields: frontmatter___order}) {
      edges {
        node {
          html
          frontmatter {
            className
            type
            location
            order
            title
            image
          }
        }
      }
    }
  }
`)
  const PartnersItems = data.md.edges.map((p, i) => {
    if (p.node.frontmatter.location === config.location) {
      var img = p.node.frontmatter.image ? GetImageByName(p.node.frontmatter.image.replace("/images/", "")) : false
      return (
        <PartnerItem>
          {p.node.frontmatter.title ? <h3>{p.node.frontmatter.title}</h3> : ""}
          {img ? <GatsbyImage image={img} title={p.node.title} className="partner pItem" key={"partner-" + i} alt={p.node.frontmatter.title} /> : ""}
          {p.node.html ? <div dangerouslySetInnerHTML={{ __html: p.node.html }} /> : ""}
        </PartnerItem>
      )
    } else {
      return ""
    }
  })

  return (
    <PartnersSection 
    id="partners" 
    className="lightgray">
      <Container>
        <h2>{config.siteMetadata.partners_title}</h2>
        {PartnersItems}
      </Container>
    </PartnersSection>
  )
}
export default Partners