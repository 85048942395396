import React from "react";
import { Link } from "gatsby";
import styled from "~modules/styled-components";

const Button = styled.div`
  width: fit-content;
  margin: 30px auto;
  padding: 10px 24px;
  background: #00a8cc;
  border-radius: 30px;
  a {
    color: #fff;
    text-decoration: none;
    text-align: center;
  }
`
const Cta = (p) => {
  return (
    <Button className="ctaButton">
     {( p.link.slice(0, 4) === 'http' ) ? <a href={p.link} rel="noopener noreferrer nofollow" target="_blank">{p.label}</a> : <Link to={p.link}>{p.label}</Link>}
    </Button>
  )
}
export default Cta