import React, { useState } from 'react';
import DateTimePicker from '~modules/react-datetime-picker';
import { GatsbyImage } from "~modules/gatsby-plugin-image"
import styled from "~modules/styled-components"
import Navbar from "~parts/navbar"
import GetImageByName from "~components/getImageByName"
import widget from "~data/widget.yml"

const Hero = () => {
  const defaultDate = () => {
    var d = new Date()
    d.setHours(12)
    d.setMinutes(0)
    return d
  }

  const [value, onChange] = useState(defaultDate());
  const [valueExit, onChangeExit] = useState(defaultDate());
  return (
    <HeroBox>
      <Navbar />
      <ParkingWidget>
        <h2>{widget.title}</h2>
        <h1>{widget.description}</h1>
        <Form method="get" action={widget.form.action} rel="noopener noreferrer nofollow" target="_blank">
        <InBox>
          <label>Parking Check In<br />
           <DateTimePicker name="arrival" onChange={onChange} value={value} required="" id="h_arrival" disableClock={true}/>
          </label>
        </InBox>
        <InBox>
          <label>Parking Check Out<br />
          <DateTimePicker name="exit" onChange={onChangeExit} value={valueExit} required="" id="h_exit" disableClock={true}/>
          </label>
        </InBox>
        <div className="search bottom">
          <input type="hidden" name={widget.form.hidden.name} value={widget.form.hidden.value} />
          <Submit type="submit" className="submit button">Search</Submit>
        </div>
      </Form>
      </ParkingWidget>
      <GatsbyImage image={GetImageByName(widget.hero_image.replace("/images/", ""))} alt="Hero Image" className="heroBG" />
    </HeroBox>
  )
}

export default Hero

const HeroBox = styled.section`
    padding: 0 0 125px;
    background-color: rgba(135,135,135,0.44)!important;
    width: 100%;
    position: relative;
    overflow: hidden;
    .heroBG {
      position: absolute;
      top: 0;
      height: 100%;
      width:100%;
    }

    @media (max-width: 767px) {
      height: 720px;
    }

`
const ParkingWidget = styled.div`
  width: 90%;
  position: relative;
  text-align: center;
  margin: 100px auto 10px;
  z-index: 2;
  padding: 35px 20px 60px;
  max-width: 1040px;
  background: rgba(0,0,0,0.6);
  background: #0000008c;
  display: grid;
  align-content: center;
  justify-content: center;
  color: #fff;
  border-radius: 11px;
  h2 {
    font-size: 45px;
    font-weight: 600;
    line-height: 1;
    text-shadow: -4px 3px 5px black;
    margin-bottom: 20px;
    }

  h1 {
    margin-bottom: 25px;
  }

  @media(min-width: 768px) {
    margin: 260px auto 80px;
    padding: 35px 35px 70px;
  }

`
const Form = styled.form`
  display: grid;
  gap: 20px;
  align-items: end;
  justify-content: center;
  @media(min-width: 768px){
    grid-template-columns: 2fr 2fr 1fr;
  }

`
const InBox = styled.div`
  display: grid;
  text-align: left;
  * {
    color: #fff;
  }

  .react-datetime-picker {
    width: 100%;
    background-color: #fff;
    outline: none!important;
    font-size: 12px;
    border: none!important;
    height: 44px;
    line-height: 18px;
    padding: 10px;
    border-radius: 5px;  
    .react-datetime-picker__inputGroup__divider:nth-child(9),
    .react-datetime-picker__inputGroup__leadingZero,
    .react-datetime-picker__inputGroup__hour,
    .react-datetime-picker__inputGroup__minute,
    .react-datetime-picker__inputGroup__amPm {
      color: #fff;
      display: none;
    }

    * {
      color: #777;
      border: none;
      outline: none;
    }  
  }

`
const Submit = styled.button`
    background: #ec0707;
    color: white;
    border-color: #ec0707;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: none;
    outline: none;
`
