import React from "react"
import Compare from "\~parts/compare"
import Partners from "\~components/partners"
import Widget from "\~parts/parkingWidget"
import Map from "\~parts/map"
import FaqSection from "\~parts/faq"
import Cta from '\~parts/button-cta'

const SectionWidgets = () => {
    return (
        <>
            <Cta link="#overview" label="Read More" />
            <Compare />
            <Partners />
            <Widget />
            <FaqSection />
            <Map />
        </>
    )
}
export default SectionWidgets