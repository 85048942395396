import React from 'react';
import styled from '~modules/styled-components'
import data from '~data/compare.yml'
import Cta from './button-cta'

const Container = styled.div`
  max-width: 1080px;
  padding: 20px;
  margin: 0 auto;
  .access_text {
    font-size: 22px;
    font-weight: 500;
    margin-top: 50px;
  }

  .access_text,
  h2 {
    text-align: center;
  }

`
const Stars = styled.div`
  display: inline-block;
  font-size: 20px;
  font-family: Times; 
  line-height: 1;
  @media (min-width: 500px){
    font-size: 30px;
  }

  &::before {
    display: block;
    font-size: 20px;
    color:  #fc0 ;
    @media (min-width: 500px){
    font-size: 30px;
   }
  }

  &.stars-1::before {
    content: "★";
  }

  &.stars-2::before {
    content: "★★";
  }

  &.stars-3::before {
    content: "★★★";
  }

  &.stars-4::before {
    content: "★★★★";
  }

  &.stars-5::before {
    content: "★★★★★";
  }

`
const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`
const Grid = styled.div`
  margin-bottom: 40px;
`
const Ass = styled.p`
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
`
const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr, 1fr;
  justify-content: center;
  gap: 30px;
  a {
    display: block;
  }

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    height: auto;
  }

  .partner:nth-child(1) {
    grid-row: 1/3;
    grid-column: 1/3;
  }

  @media(min-width: 1024px){
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    .partner:nth-child(1) {
    grid-row: 1/4;
    grid-column: 2/3;
    align-self: center;
   }
  }

`
const StarsInner = styled.div`
  max-width: 550px;
  margin 0 auto;
`
const Compare = () => {
  const Items = (items) => {
    var sum = 0
    const Lines = items.map((item, i) => {
      sum += item.value
    return (
      <Row key={"starTrack-"+i}>
        <span>{item.label}</span>
        <Stars className={"stars-" + item.value} />
      </Row>
    )
  })
return (
       <Grid>
         {Lines}
         <br/>
         <Row>
          <Ass><b>Overall</b> <b>{sum/items.length}</b></Ass>
          <Stars className={"stars-" + Math.round
(sum/items.length)} />
         </Row>
        </Grid>
      )
   }

  return (
    <section id="compare" className='white'>
      <Container>
      <StarsInner>
        <h2>{data.title}</h2>
        <h3 dangerouslySetInnerHTML={{ __html: data.on.title }} className="access_text" />
        {Items(data.on.items)}
         <Cta label = {data.on.cta.label} link = {data.on.cta.link} />
        <p dangerouslySetInnerHTML={{ __html: data.off.title }} className="access_text" />
        {Items(data.off.items)}
         <Cta label = {data.off.cta.label} link = {data.off.cta.link} />
        </StarsInner>
      </Container>
    </section>
  )
}

export default Compare