import React from 'react';
import styled from '~modules/styled-components'
import { MDXRenderer } from '~modules/gatsby-plugin-mdx'
import Cta from '~parts/button-cta'

const Container = styled.div`
  max-width: 1080px;
  padding: 20px;
  margin: 0 auto;
`
const TextMDX = (n) => {
  var sectionClass = n.mdx.frontmatter.className
  var sectionId = n.mdx.frontmatter.id

  return (
    <section
      id={sectionId}
      className={sectionClass}>
      <Container>
        <h2>{n.mdx.frontmatter.title}</h2>
        <MDXRenderer>{n.mdx.body}</MDXRenderer>
      </Container>
    </section>
  )
}

export default TextMDX