import React from "react"
import { GatsbyImage } from "~modules/gatsby-plugin-image"
import GetImageByName from "~components/getImageByName"
import styled from '~modules/styled-components'
import data from '~data/map.yml'

const Container = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`
const Map = () => {
  return (
    <section id="maps">
      <Container>
        <h2>{data.title}</h2>
          <GatsbyImage image={GetImageByName(data.image.replace("/images/", ""))} alt={data.title} />
      </Container>
    </section>
  )
}

export default Map
